.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
  margin: auto;
}
.lds-ellipsis div {
  position: absolute;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.busy-overlay {
  position: absolute;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all;
  pointer-events: none;
  padding-bottom: 50px;
}
.busy-overlay.active {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.no-bg {
  background: none;
}
.spinner {
  width: 45px;
  height: 45px;
  border: 3px solid #009ef7;
  border-right: 3px solid transparent;
  border-radius: 50%;
  animation: spinner .5s linear infinite;
  margin: auto auto 15px auto;
}
.spinner.sp-small {
  width: 28px;
  height: 28px;
}
.spinner-text {
  margin: 0 auto auto auto;
  color: #fff;
}
@keyframes spinner{
  to {
      -webkit-transform:rotate(1turn);
      transform:rotate(1turn)
  }
}