.help-section {
  h3 {
    font-size: 24px;
  }
  h4 {
    margin: 0 0 10px 0;
  }
  h2 {
    margin: 0 0 10px 0;
    color: #ffe80a;
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  }
  p {
    margin: 0 0 15px 0;
  }
  li {
    color: yellow;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .sbtn {
    margin: 0 10px 20px 0;
    min-width: 125px;
  }
  .table-of-content {
    max-width: 300px;
    margin: 0 0 50px 0;
  }
}
.qr-codes {
  display: flex;
  gap: 12px;
}
.qrcode-wrapper {
  border-radius: 10px;
  box-shadow: 0 0 15px #00000052;
  display: inline-block;
  padding: 10px;
  background-color: #fff;
  text-decoration: none;
  img {
    max-width: 100%;
  }
  strong {
    color: #333;
    display: block;
    text-align: center;
    i {
      margin-right: 5px;
      margin-left: -5px;
    }
  }
}