.v-switch {
    font-size: 16px;
    label {
        vertical-align: top;
        line-height: 1.9;
        color: #fff;
    }
    div {
        display: inline-block;
        position: relative;
        margin: 0 10px;
        cursor: pointer;
        width: 54px;
        height: 29px;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
    }
    span {
        left: 2px;
        width: 25px;
        height: 25px;
        top: 2px;
        border-radius: 25px;
        background-color: #ffe800;
        transition: all 0.3s;
        position: absolute;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5), inset 0 7px 12px rgba(0, 0, 0, 0.35);
    }
    &.active span {
        left: 27px;
    }
    &.disabled {
        div {
            cursor: default;
        }
        span {
            background-color: #cecece;
            box-shadow: inset 0 7px 12px rgba(0, 0, 0, 0.35);
        }
    }
}