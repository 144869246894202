.radio-button {
    display: flex;
    cursor: pointer;
    >div {
        display: inline-block;
        position: relative;
        width: 29px;
        height: 29px;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 5px rgb(0 0 0 / 60%);
        border: 1px solid rgb(0 0 0 / 50%);
        span {
            left: 4px;
            width: 19px;
            height: 19px;
            top: 4px;
            border-radius: 25px;
            background-color: #ffffff;
            transition: all 0.2s;
            position: absolute;
            transform: scale(0);
            box-shadow: 0 4px 6px rgb(0 0 0 / 50%), inset 0 7px 12px rgb(0 0 0 / 35%);
        }
    }
    &.checked span{
        transform: scale(1);
    }
    label {
        margin: auto 0 auto 5px;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
    }
}