.rewards {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  margin: auto;
  h3 {
    margin: 0;
  }
  .lds-ellipsis {
    margin: auto;
  }
  b {
    font-size: 18px;
    margin: 0 auto;
    color: #FFE800;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
  }
  strong {
    font-size: 16px;
    margin: 0 auto 10px;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
  }
}
.reward-note {
  font-size: 13px;
  font-weight: 600;
  color: #0000007a;
  margin-top: 5px;
  text-shadow: none;
}
.tokens-box {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  .tkb {
    flex: 1 1;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px 5px;
    cursor: default;
    margin: 2px;
    h5{
      font-size: 22px;
      margin: 0 0 2px;
      color: #FFE800;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }
    @media (max-width: 767px) {
      flex: 1 0 100%;
    }
    b {
      font-size: 32px;
    }
  }
  &.user-wallet .tkb{
    @media (min-width: 768px) and (max-width: 1360px) {
      flex: 1 0 calc(50% - 10px);
    }
  }
}
.tkb-hover {
  font-size: 13px;
}
.tokens-box .tkb-fullname {
  font-size: 13px;
  height: 32px;
  font-weight: 500;
  color: #fff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}
.tokens-box .tkb b {
  font-size: 23px;
}
.tokens-box .token-source {
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  text-align: left;
  background-color: rgba(2, 7, 30, 0.5);
  padding: 3px 9px;
  margin-top: -5px;
  border-radius: 4px;
  line-height: 1.8;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
  height: 100%;
}
.tsr-red {
  color: #ff5959;
}
.tsr-green {
  color: #2acf1f;
}
.tsr-white {
  font-weight: bold;
}
.ts-row span {
  width: 100px;
  color: #fff;
  display: inline-block;
}

table{
  width:100%;
  table-layout: fixed;
}
thead th {
    padding: 15px 15px 17px;
}
th {
  padding: 6px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  border-bottom: solid 1px rgba(255,255,255,0.1);
  text-transform: uppercase;
  @media (max-width: 767px) {
    padding: 0 5px;
    font-size: 14px;
  }
}
td {
  padding: 6px 9px;
  text-align: left;
  vertical-align:middle;
  font-size: 15px;
  color: #fff;
  border-bottom: solid 1px rgba(255,255,255,0.1);
}
tbody tr:last-child td,
tbody tr:last-child th {
  border-bottom: solid 1px rgba(255,255,255,0.3);
}
tfoot tr:last-child td,
tfoot tr:last-child th {
    border-bottom: none;
}

.flip-container {perspective: 1000px; transform-style: preserve-3d;}
.flip-container:hover .back {transform: rotateY(0deg);}
.flip-container:hover .front {transform: rotateY(180deg);}

.flipper {transition: 0.6s; transform-style: preserve-3d;	position: relative;}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	transition: 0.6s;
	transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.front {z-index: 2;	transform: rotateY(0deg);position: relative;}
.back {transform: rotateY(-180deg);}

.vertical.flip-container {position: relative;}
.vertical .back {	transform: rotateX(180deg);}
.vertical.flip-container:hover .back  {transform: rotateX(0deg);}
.vertical.flip-container:hover .front {transform: rotateX(180deg);}
.stk-res-wrapper {
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px 8px;
  border-radius: 5px;
}
.stk-res-l {
  color: #ffe800;
}
.stk-res-r {
  color: #00ff43;
}

.dexo-box {
  margin: 15px auto 0;
  display: flex;
  max-width: 550px;
  flex-wrap: wrap;
}
.dexo-box .sbtn {
  margin: auto 0 auto auto;
  width: 250px;
}
@media (min-width:992px) {
  .staking-table {
    th:last-child, td:last-child {
      width: 113px;
    }
    th:first-child, td:first-child {
      width: 240px;
    }
    label {
      display: none;
    }
  }
}
@media (max-width:991px) {
  .staking-table {
    thead {
      display: none;
    }
    tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
    label {
      font-weight: light;
      margin-right: 5px;
      color: #c1d9e9;
    }
  }
}
.days-range {
  position: absolute;
  bottom: -15px;
}
.days-range.dr-start {
  left: 0;
}
.days-range.dr-end {
  right: 0;
}

.reflink-wrapper {
  p {
    margin: 10px 0 5px 0;
  }
  .reflink {
    position: relative;
    background-color: #0000004a;
    border-radius: 5px;
    padding: 5px 30px 5px 10px;
    font-size: 14px;
    box-shadow: 0 0 9px #00000052 inset;
    color: #ffe800;
    &.copyable {
      cursor: pointer;
    }
    >div {
      overflow: hidden;
      white-space: nowrap;
    }
    .copy-reflink {
      pointer-events: none;
      color: white;
      position: absolute;
      right: 8px;
      top: 20%;
    }
  }
}

.warning {
  font-size: 13px;
  margin-top: 3px;
  color: #ffeb00;
}
.table-wrapper {
  overflow: auto;
  table {
    min-width: 575px;
    th {
      color: #ffeb00;
    }
    tfoot {
      margin-top: 15px;
    }
    .grayed {
      th,td {
        color: rgba(255, 255, 255, 0.4);
      }

    }
  }
}
.yellow-text {
  color: #ffeb00;
}
.tinted {
  background-color: rgba(255, 255, 255, 0.1);
}
.rankbox-header {
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px 0;
  margin-bottom: 9px;
  >div:last-child {
    min-height: 20px;
    font-size: 13px;
  }
}
.rankbox-body {
  text-align: center;
  display: flex;
  .rb-col {
    flex: 1;
    margin: 0 5px;
    >div:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding-bottom: 4px;
      margin-bottom: 5px;
    }
  }
}