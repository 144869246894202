.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.7);
  width: 208px;
  transition: all 0.5s;
  .sm-header {
    padding: 5px 12px 0;
    height: 136px;
    background-color: rgb(0, 0, 0, 0.6);
    position: relative;
    transition: all 0.5s;
    img {
      position: absolute;
      &:first-child {
        transition: all 0.5s;
        width: 181px;
      }
      &:last-child {
        transition: all 0.2s;
        opacity: 0;
        left: 2px;
        right: 0;
        top: 7px;
        width: 51px;
      }
    }
  }
  .sm-button {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(0, 0, 0, 0);
    font-weight: 100;
    padding: 14px 18px;
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.03);
    cursor: pointer;
    text-decoration: none;
    display: block;
    position: relative;
    white-space: nowrap;
    transition: all 0.3s;
    &:hover,&:active,&:focus,&.active {
      color: rgba(255, 255, 255, 1);
      background-color: rgba(0, 0, 0, 0.15);
    }
    &.active {
      font-weight: 500;
    }
    i {
      margin-right: 10px;
      width: 18px;
      text-align: center;
    }
    span {
      transition: all 0.3s;
    }
    .sm-badge {
      position: absolute;
      right: 16px;
      top: 14px;
      color: #ffffff;
      background-color: #c91a02;
      padding: 0 5px;
      text-align: center;
      border-radius: 4px;
      font-size: 13px;
      transition: 0.3s all;
    }
  }
}

@media (max-width:991px) {
  .side-menu {
    width: 55px;
    .sm-header {
      padding: 5px 12px 0;
      height: 64px;
      background-color: rgb(0, 0, 0, 0.6);
      img {
        &:first-child {
          width: 34px;
          opacity: 0;
        }
        &:last-child {
          transition: all 0.5s 0.3s;
          opacity: 1;
        }
      }
    }
    .sm-button {
      padding: 8px 18px;
      span {
        font-size: 9px;
        display: block;
        margin-left: -11px;
        width: 41px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
      }
      .sm-badge {
        right: 3px;
        font-size: 11px;
        top: 25px;
      }
    }
  }
}