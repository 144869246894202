.fiveplans {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 20px;
  >div {
    flex: 1;
    margin: 0;
    height: auto;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
  ul {
    margin: 10px 0 0 0;
    padding-left: 35px;
  }
}
.plan-notes {
  color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgba($color: #fff, $alpha: 0.2);
  padding: 10px;
  border-radius: 5px;
  b {
    display: block;
    margin-bottom: 10px;
  }
  p {
    margin: 0 0 5px 0;
  }
}
