.annce-body {
    position: relative;
    color: #313131;
    border: 1px solid rgba(255, 255, 255, 0.15);
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    margin: 9px 0;
    display: flex;
    min-height: 80px;
}
.annce-body .ab-sign {
    color: #fff;
    font-size: 30px;
    flex: 0 0 70px;
    display: flex;
    margin: -5px 14px -6px -11px;
    border-radius: 9px 0 0 9px;
}
.annce-body .ab-sign i {
    margin: auto;
}
.annce-body.ab-green .ab-sign{
    background-color: #0fa916;
}
.annce-body.ab-yellow .ab-sign {
    background-color: #ffab00;
}
.annce-body.ab-red .ab-sign {
    background-color: #dd0000;
}
.annce-body .ab-text {
    flex: 1 1;
    overflow-wrap: anywhere;
}
.annce-body .ab-text h4{
    margin: 0 0 5px 0;
}
.annce-body .ab-text p{
    margin: 0;
}
.annce-body .anc-date{
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 1px 9px 1px;
    margin-left: 8px;
    color: #202020;
    white-space: nowrap;
}
.ab-remove {
    position: absolute;
    cursor: pointer;
    width: 18px;
    height: 18px;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    right: -8px;
    top: -8px;
    box-shadow: 0 0 2px black;
}
.colors-group {
    display: flex;
}
.colors-group .sbtn-primary {
    margin-left: auto;
    min-width: 120px;
}
.color-button {
    height: 35px;
    width: 65px;
    border-radius: 5px;
    border: 3px solid rgba(255, 255, 255, 0);
    margin-right: 6px;
    cursor: pointer;
    transition: all 0.2s;
}
.color-button.checked {
    border-color: white;
}
.color-button.cb-red {
    background-color: #dd0000;
}
.color-button.cb-yellow {
    background-color: #ffab00;
}
.color-button.cb-green {
    background-color: #0fa916;
}
.cs-textinput {
    resize: vertical;
}