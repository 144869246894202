.contact-us {
    &>.white-box {
        margin: auto;
        max-width: 550px;
        h4 {
            padding-bottom: 10px;
            font-size: 21px;
        }
        button {
            min-width: 150px;
            margin: 10px auto auto auto;
            display: block;
        }
        .validation-text {
            display: none;
            color: #ffffff;
            font-size: 13px;
            text-shadow: 0 0 8px white;
            background-color: red;
            padding: 1px 8px 2px;
            border-radius: 5px;
        }
        .afb-input.invalid {
            .validation-text{
                display: inline-block;
            }
            .cs-textinput {
                box-shadow: 0 0 5px red inset;
            }
        }
    }
    .form-select {
        display: block;
        width: 100%;
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &:focus {
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }
    }
}