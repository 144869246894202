html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }
}
.force-scroll {
  overflow-y: scroll;
}
.main {
  background-image: url("./bg2.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff54;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #31465c; 
}
.guest-header {
  display: flex;
  padding: 5px 12px 7px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.guest-header img {
  max-width: 200px;
}
.alert {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 0.75rem 1.25rem;
  margin: 0 0 1rem 0;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 15px;
  text-align: center;
}
.header {
  padding: 6px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  -webkit-backdrop-filter: blur(4px) brightness(1.1);
  backdrop-filter: blur(4px) brightness(1.1);
  &>div {
    margin-left: auto;
    .sbtn {
      @media (max-width:767px) {
        font-size: 12px;
      }
      @media (max-width:415px) {
        width: 100%;
        margin: 5px 0 0 0 !important;
      }
    }
    @media (max-width:375px) {
      margin: 5px 0 0 0;
      flex: 1 0 100%;
    }
  }
  &.guest-mode {
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 0 #00000096;
    >div {
      margin: auto;
      background-color: #0000003b;
      padding: 0 9px;
      border-radius: 5px;
    }
  }
  .show-add-token, .start-tour {
    margin: auto 0;
    @media (max-width:767px) {
      font-size: 12px;
    }
  }
  .show-add-token {
    @media (max-width:375px) {
      flex: 1;
    }
  }
  .sgn-wrapper {
    @media (min-width:416px) and (max-width:529px) {
      margin-top: 5px;
      width: 100%;
      display: flex;
      >button {
        flex: 1;
      }
    }
    @media (max-width:529px) {
      margin-left: 0;
    }
  }
  &.has-alert {
    margin-bottom: 50px;
  }
  .wrong-net {
    position: absolute;
    bottom: -64px;
    left: 5px;
    right: 5px;
    text-align: center;
    >span {
      background-color: #d50000;
      color: #fff;
      padding: 10px 16px;
      font-family: "Poppins",sans-serif;
      border-radius: 5px;
      cursor: pointer;
      display: block;
      white-space: normal;
    }
  }
}
.brand {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
.sbtn {
  border: 1px solid transparent;
  border-radius: 25px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  transition: all 0.2s;
}
.sbtn-primary {
  background-color: #FFE800;
  color: #464110;
}
.sbtn-primary:hover,
.sbtn-primary:focus,
.sbtn-primary:active {
  background-color: #ecd900;
}
.sbtn-secondary {
  background-color: #ffffff;
  color: #333;
}
.sbtn-secondary:hover,
.sbtn-secondary:focus,
.sbtn-secondary:active {
  background-color: #ececec;
}
.sbtn-success {
  background-color: #6fcf00;
  color: #fff;
}
.sbtn-success:hover,
.sbtn-success:focus,
.sbtn-success:active {
  background-color: #8be128;
}

.sbtn-info {
  background-color: #0499de;
  color: #fff;
}
.sbtn-info:hover,
.sbtn-info:focus,
.sbtn-info:active {
  background-color: #1ca2e0;
}
.sbtn-warning {
  background-color: #ff7300;
  color: #fff;
}
.sbtn-warning:hover,
.sbtn-warning:focus,
.sbtn-warning:active {
  background-color: #ff8725;
}
.sbtn-danger {
  background-color: #b80f0f;
  color: #fff;
}
.sbtn-danger:hover,
.sbtn-danger:focus,
.sbtn-danger:active {
  background-color: #c91010;
}
.sbtn-disabled {
  background-color: #e2e2e2;
  color: #797979;
  cursor: auto;
}
.grouped-buttons {
  &>button {
    font-weight: 600;
    color: #fff;
    text-shadow: 0 0 2px #000;
    &.sbtn-disabled {
      text-shadow: none;
      color: #797979 !important;
    }
    &:first-child {
      border-radius: 15px 0 0 15px;
      border-right: 1px solid #00000038;
    }
    &:last-child {
      border-radius: 0 15px 15px 0;
    }
  }
}
.white-box {
  position: relative;
  margin: auto;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  -webkit-backdrop-filter: blur(4px) brightness(1.1);
  backdrop-filter: blur(4px) brightness(1.1);
  &.wb-flex {
    display: flex;
    flex-direction: column;
    div {
      text-align: center;
      margin: auto;
      font-size: 14px;
    }
  }
}
.empty-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
}
.white-box>h3 {
  margin: 0 0 15px;
  text-align: center;
}
.row.tight {
  margin-right: -5px;
  margin-left: -5px;
}
.row.tight>div{
  padding-left: 5px;
  padding-right: 5px;
}
.footer {
  color: #969696;
  text-align: center;
  margin-top: auto;
  background-color: #0d011e;
  font-size: 12px;
  padding: 5px 0 7px 5px;
  z-index: 1;
}
.footer-links {
  margin-bottom: 4px;
}
.footer-links>a{
  color: #797979;
  display: inline-block;
  margin: 0 5px;
  text-decoration: none;
  transition: all 0.3s;
  font-size: 14px;
}
.footer-links>a:hover{
  color: #fff;
}
hr {
  border-color: rgba(255, 255, 255, 0.25);
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.introjs-tooltip-header {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 5px !important;
}
.introjs-tooltiptext {
  padding: 0 20px !important;
}
.introjs-skipbutton {
  padding: 0 0 10px 0 !important;
  font-size: 25px !important;
}
/*App.css*/

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.main>.busy-overlay {
  position: fixed;
}
.content-wrapper {
  padding-left: 208px;
  transition: all 0.5s;
  @media (max-width:991px) {
    padding-left: 53px;
  }
}

.user-address {
  text-shadow: 1px 1px 0 white;
  color: #313131;
  border: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 13px;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 9px 0;
  &.elevated {
    background-color: #ffe800;
  }
  span {
    &.short-address {
      display: none;
    }
    @media (max-width:991px) {
      &.full-address  {
        display: none;
      }
      &.short-address  {
        display: inline;
      }
    }
  }
}
.user-address i{
  margin-right: 5px;
}
.at-btns-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e3e3e3;
}
.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  background-color: #000000ab;
  animation: fadeIn 0.3s;
  animation-fill-mode: both;
  .modal-body {
    max-width: 550px;
    margin: auto;
    position: relative;
    color: #fff;
    background-color: rgb(255 255 255);
    border-radius: 6px;
    padding: 15px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
    @media (max-width: 590px) {
      margin: auto 15px;
    }
    h3 {
      color: #333;
      margin: 0 0 10px 0;
      text-align: center;
      font-weight: 500;
    }
  }
  .modal-footer {
    text-align: center;
  }
  .sbtn-primary {
    min-width: 200px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ReactModal__Content {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}
.rm-footer {
  text-align: right;
}
.rm-footer .sbtn {
  margin-left: 5px;
}
.loading-text {
  padding: 120px 50px 15px;
}
.info-text {
  margin: 15px;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
}

.afb-input {
  margin-bottom: 5px;
}
.afb-input label{
  font-size: 14px;
}
.cs-textinput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-family: inherit;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.cs-textinput:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.cs-textinput:disabled {
  background-color: #d4d4d4;
}
.tabulator {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: none !important;
}
.tabulator-row {
  background-color: initial !important;
}
.tabulator .tabulator-tableholder .tabulator-table {
  background-color: initial !important;
}
.tabulator .tabulator-header .tabulator-col {
  background: initial !important;
}
.tabulator-row.tabulator-row-even {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.tabulator .tabulator-header {
  border-bottom: rgba(255, 255, 255, 0.2) !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.tabulator .tabulator-header .tabulator-col {
  min-width: 116px !important;
}

.flash-wrapper {
  position: fixed;
  top: 50px;
  z-index: 3;
  text-align: center;
  left: 239px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  .flash {
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    padding: 5px 15px;
    margin-bottom: 10px;
    animation: appearFromTop 6s;
    animation-fill-mode: both;
    box-shadow: 0 7px 19px rgba(0,0,0,0.3);
    pointer-events: all;
    &.success {
      background-color: #71c207;
    }
    &.warning {
      background-color: #e6a10f;
    }
    &.danger {
      background-color: #c2183d;
    }
  }
  @keyframes appearFromTop {
    from,10%,12%,15%,17% {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: translate3d(0, -3000px, 0) scaleY(3);
    }
    10% {
      opacity: 1;
      transform: translate3d(0, 25px, 0) scaleY(0.9);
    }
    12% {
      transform: translate3d(0, -10px, 0) scaleY(0.95);
    }
    15% {
      transform: translate3d(0, 5px, 0) scaleY(0.985);
    }
    17% {
      transform: translate3d(0, 0, 0);
    }
    90% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      margin-bottom: 10px;
    }
    to {
      opacity: 0;
      margin-bottom: -34px;
    }
  }
  
}

.dexo-cards-container {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px 5px -20px;
  flex-wrap: wrap;
  padding: 15px;
  h3 {
    flex: 1 0 100%;
    text-align: center;
    color: #fff;
    margin: 0 0 15px 0;
  }
  .dexo-cards {
    color: #fff;
    position: relative;
    padding: 15px;
    border-radius: 6px;
    flex: 1 1 24%;
    margin: 0 5px 5px 5px;
    .dc-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      h4 {
        margin: 0 0 5px 0;
        font-size: 18px;
      }
      i {
        font-size: 39px;
        margin: 0 15px 10px 0;
      }
    }
    .dc-value {
      text-align: center;
      font-size: 13px;
      div {
        display: inline-block;
        font-size: 20px;
      }
    }
    &:nth-child(2) {
      background: #00a7a6;
      background: linear-gradient(90deg, #00a7a6dd 0%, #11d5e1bb 100%);
    }
    &:nth-child(3) {
      background: #ff936a;
      background: linear-gradient(90deg, #ff936add 0%, #ffb697bb 100%);
    }
    &:nth-child(4) {
      background: #0cbb7c;
      background: linear-gradient(90deg, #0cbb7cdd 0%, #0cf4a4bb 100%);
    }
    &:nth-child(5) {
      background: #fd5c70;
      background: linear-gradient(90deg, #fd5c70dd 0%, #fd8c9fbb 100%);
    }
  }
}