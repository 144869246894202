
.packages {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
}
.token-options {
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
  flex: 1 1 60%;
  padding-right: 10px;
  gap: 20px;
  >span {
    display: block;
    width: 2px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}
.dexo-switch {
  .tokens-description {
    flex: 1 0 100%;
    margin-bottom: 10px;
  }
  padding: 0 15px;
  color: #fff;
  margin-bottom: 15px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.st-header {
  text-align: center;
  margin: 50px 0 20px;
  color: #fff;
}
.pkg {
  margin: 5px;
  padding: 15px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.15);
  min-width: 190px;
  flex: 1 1 277px;
  color: #fff;
  display: flex;
  min-height: 280px;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(4px) brightness(1.1);
  backdrop-filter: blur(4px) brightness(1.1);
}
.pkg-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: auto;
}
.pkg:nth-child(1) {
  border-top: 6px solid #4a94e7;
}
.pkg:nth-child(2) {
  border-top: 6px solid #29e1c8;
}
.pkg:nth-child(3) {
  border-top: 6px solid #c8e646;
}
.pkg:nth-child(4) {
  border-top: 6px solid #ffee00;
}
.pkg:nth-child(5) {
  border-top: 6px solid #f57830;
}
.pkg:nth-child(6) {
  border-top: 6px solid #dd3d6d;
}
@media (min-width: 1100px ) {
  .flex-breaker {
    flex: 1 0 100%;
  } 
}
.pkg-price {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin: 15px 0;
}
.pkg-text {
  cursor: pointer;
  font-size: 14px;
  padding-top: 5px;
  text-align: center;
  color: #ffe808;
}
.pkg-text:hover {
  text-decoration: underline;
}
.pkg-note {
  font-size: 14px;
  margin: 0 -5px 5px;
}
.pkg-button {
  text-align: center;
  padding-top: 15px;
}
.pkg-button .sbtn{
  display: block;
  width: 100%;
}

.platform-fee {
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  display: flex;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px) brightness(1.1);
  p {
    margin: 0;
  }
}