.tokens-info h3 {
  color: #fff;
  text-align: center;
}
.ti-small {
  font-weight: 100;
  text-transform: capitalize;
  @media (max-width: 767px) {
    display: none;
  }
}
td>i.fa-chevron-up,
td>i.fa-chevron-down {
  margin-right: 5px;
  padding: 1px 3px;
  border-radius: 3px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
}
td>i.fa-chevron-up {
  background-color: #24b505;
}
td>i.fa-chevron-down {
  background-color: #c91a02;
}
.charts-wrapper {
  margin-bottom: 10px;
}
.charts-wrapper .row>div {
  margin-bottom: 25px;
}
.chart-wrapper {
  height: 171px;
}
.chart-label {
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  position: absolute;
  left: 0;
  bottom: 5px;
  right: 0;
}
.chart-label span{
  font-size: 11px;
}
.tweets-col {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 6px;
}
.big-intro {
  text-align: center;
  max-width: 580px;
  margin: 0 auto 27px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.big-intro h1 {
  line-height: 1.1;
  font-size: 50px;
  color: #fff;
  margin: 0 0 20px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  @media (max-width: 767px) {
    font-size: 34px;
  }
}
.big-intro h1 span {
  color: #FFE800;
}
.big-intro p {
  color: #fff;
}
.boards .row>div {
  margin-bottom: 10px;
}
.board-box {
  position: relative;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  height: 100%;
  -webkit-backdrop-filter: blur(4px) brightness(1.1);
  backdrop-filter: blur(4px) brightness(1.1);
  &.bb-collapsed {
    height: 54px;
    overflow: hidden;
  }
  >h3 {
    color: #fff;
    margin: 0 0 15px;
    text-align: center;
  }
}
.admin-editor .board-box h3 {
  text-align: left;
}