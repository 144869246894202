.news-scroller {
  max-height: 675px;
  overflow-y: scroll;
  padding-right: 15px;;
}
.news-card {
  display: block;
  margin-bottom: 5px;
  padding-top: 10px;
  color: #fff;
  text-decoration: none;
  border-top: 1px solid rgba(255, 255, 255, 0.33);
}
.news-card b {
  display: block;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
}
.news-card img{
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 5px;
}
.news-feed .white-box .row>div:last-child {
  display: none;
}